import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "quantity" ]

  increment() {
    const product = this.quantityTarget
    product.value = parseInt(product.value) + parseInt(product.dataset.batchSize)
    this._round(product)
  }

  decrement() {
    const product = this.quantityTarget
    product.value = parseInt(product.value) - parseInt(product.dataset.batchSize)
    this._round(product)
  }

  fill_in() {
    this._round(this.quantityTarget)
  }

  _round(product) {
    const batchSize = parseInt(product.dataset.batchSize)
    const selectedAmount = parseInt(product.value)

    if (selectedAmount < batchSize ) {
      product.value = batchSize
    } else {
      product.value = Math.round(selectedAmount/batchSize) * batchSize
    }
  }
}
