// app/javascript/controllers/contactsearch_controller.js
import { Controller } from "stimulus";
import Rails from "@rails/ujs"
import $ from "jquery";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    $(this.inputTarget).select2({
      placeholder: "📞, 📧, Navn eller Kundenummmer",
      dropdownAutoWidth: true,
      ajax: {
        url: '/contact_search',
        quietMillis: 200,
        datatype: 'json',
        data: function (term) {
          return {
            q: term
          }
        },
      },
      escapeMarkup: function(markup) {
        return markup;
      },
      minimumInputLength: 3
    });

    $(this.inputTarget).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });

    $(this.inputTarget).on('select2:select', function (e) {
      $.post({
        url: '/pretenders',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        data: {customer_no: e.params.data.id},
        success: function(data) {
          console.log(data);
        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.error('Request failed with status ' + jqXHR.status + ': ' + jqXHR.responseText);
        }
      });
    });
  }
}
