import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ ]

  connect() {
    let target = this.element

    Rails.ajax({
      type: "get",
      url: this.data.get("url"),
      success: function(data) {
        target.insertAdjacentHTML("beforeend", data.body.innerHTML)
      }
    });
  }
}
