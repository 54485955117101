import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["shippingMethod"]
  static shippingTotalManager = ""

  connect() {
    console.log("ShippingSelectController connected")
    var input = {
      orderTotal: $('#summary-order-total'),
      taxTotal: $('[data-hook="tax-total"]'),
      shipmentTotal: $('[data-hook="shipping-total"]'),
      shippingMethods: $('input[data-behavior="shipping-method-selector"]')
    }
    this.shippingTotalManager = new ShippingTotalManager(input)
    this.shippingTotalManager.bindEvent()
  }

  select_group() {
    this.shippingMethodTarget.checked = true
    this.shippingTotalManager.calculateShipmentTotal()
  }
}
