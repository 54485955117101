import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "shippingModal", "deliveryInfo" ]

  openModal(event) {
    this._setLoadSpinner()
    this._setDeliveryInfo(event.currentTarget.dataset.url)
    $(this.shippingModalTarget).modal("show")
  }

  closeModal() {
    $(this.shippingModalTarget).modal("hide")
    this._removeDeliveryInfo()
  }

  _setLoadSpinner() {
    this.deliveryInfoTarget.innerHTML = "<div class='spinner-border text-primary' role='status'><span class='sr-only'>Indlæser...</span></div>"
  }

  _setDeliveryInfo(url) {
    let target = this.deliveryInfoTarget

    Rails.ajax({
      type: "get",
      url: url,
      success: function(data) {
        target.innerHTML = data.body.innerHTML
      }
    });
  }

  _removeDeliveryInfo() {
    this.deliveryInfoTarget.innerHTML = ""
  }
}
