import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "response" ]
  static values = {
    input: String,
    endpoint: String,
    inputTimer: Object
  }

  update(event) {
    this.inputValue = event.currentTarget.value
    this.endpointValue = event.currentTarget.dataset.endpoint

    this._setLoadSpinner()
    this._processInput()
  }

  _setLoadSpinner() {
    this.responseTarget.innerHTML = "<div class='spinner-border text-primary' role='status'><span class='sr-only'>Søger...</span></div>"
  }

  _processInput() {
    clearTimeout(this.inputTimerValue)

    this.inputTimerValue = setTimeout(() => { this._setResponse() }, 350)
  }

  _setResponse() {
    let target = this.responseTarget

    Rails.ajax({
      type: "get",
      url: this.endpointValue,
      data: new URLSearchParams({ 'keywords': this.inputValue }).toString(),
      success: function(data) {
        target.innerHTML = data.body.innerHTML
      }
    });
  }
}
