import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "quantity" ]

  increment(event) {
    const line_item = this._line_item(event)
    line_item.value = parseInt(line_item.value) + parseInt(line_item.dataset.batchSize)
    this._update(event, line_item)
  }

  decrement(event) {
    const line_item = this._line_item(event)
    line_item.value = parseInt(line_item.value) - parseInt(line_item.dataset.batchSize)
    this._update(event, line_item)
  }

  fill_in(event) {
    const line_item = this._line_item(event)
    this._update(event, line_item)
  }

  remove(event) {
    const line_item = this._line_item(event)
    line_item.value = parseInt(0)
    this._update(event, line_item)
  }

  comment() {
    this.formTarget.submit()
  }

  _line_item(event) {
    return this.quantityTargets.find(target => target.dataset.id === event.currentTarget.dataset.id)
  }

  _round(line_item) {
    const batchSize = parseInt(line_item.dataset.batchSize)
    const selectedAmount = parseInt(line_item.value)

    if (selectedAmount === 0) return

    if (selectedAmount < batchSize ) {
      line_item.value = batchSize
    } else {
      line_item.value = Math.round(selectedAmount/batchSize) * batchSize
    }
  }

  _update(event, line_item) {
    this._round(line_item)
    this.formTarget.submit()
    event.preventDefault()
  }
}
