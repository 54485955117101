import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "map", "location", "checkbox" ]

  connect() {
    var activeInfoWindow = null
    let map = this._createMap()
    let icon = this._createIcon()

    this._setLocationMarkers(map, icon)
  }

  _createMap() {
    let options = {
      zoom: 7,
      center: new google.maps.LatLng(56.243629, 10.684204),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    return new google.maps.Map(this.mapTarget, options)
  }

  _createIcon() {
    return new google.maps.MarkerImage(new google.maps.Size(18, 25), new google.maps.Point(350, 50))
  }

  _createPoint(location_json) {
    let { gps_lat, gps_long } = JSON.parse(location_json)

    if (gps_lat && gps_long) {
      return new google.maps.LatLng(gps_lat, gps_long)
    } else {
      return new google.maps.LatLng(55.39436, 10.415254)
    }
  }

  _createMarker(point, map, icon) {
    return new google.maps.Marker({ position: point, map: map })
  }

  _createInfoWindow(content) {
    return new google.maps.InfoWindow({ content: content })
  }

  _setLocationMarkers(map, icon) {
    this.locationTargets.forEach(location => {
      let marker = this._createMarker(this._createPoint(location.dataset.json), map, icon)
      let infoWindow = this._createInfoWindow(location.dataset.infoWindowContent)

      google.maps.event.addListener(marker, "click", () => {
        let locationCheckbox = this.checkboxTargets.find(checkbox => checkbox.id == location.dataset.listItemId)

        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }

        infoWindow.open(map, marker)
        this.activeInfoWindow = infoWindow
        locationCheckbox.checked = true
      })

      if (this.locationTargets.length == 1) {
        infoWindow.open(map, marker)
      }
    })
  }
}
