// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"


export default class extends Controller {
  connect() {
  }

  search(event) {
    let search_field = document.getElementById("keywords")
    search_field.dispatchEvent(new KeyboardEvent('keyup'))
    setTimeout(() => { search_field.value = event.target.dataset.keyword; search_field.dispatchEvent(new KeyboardEvent('keyup')) }, 350)
  }
}
