import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    vehicle: String
  }

  hijack(event) {
    event.preventDefault();
    location.href = event.target.href.replace("/t/", "/vehicle_taxons/" + this.data.get("vehicle") + "/");
  }
}
