import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  show(event) {
    window.scrollTo(0, 0)
    const dropdownId = event.currentTarget.dataset.dropdownId
    document.getElementById('header').style.position = "absolute"
    this.dropdownTargets.forEach(dropdown => {
      dropdown.id == dropdownId ? dropdown.classList.add("d-md-block") : ""
    })
  }

  hide(event) {
    const dropdownId = event.currentTarget.dataset.dropdownId
    document.getElementById('header').style.position = "fixed"
    this.dropdownTargets.forEach(dropdown => {
      dropdown.id == dropdownId ? dropdown.classList.remove("d-md-block") : ""
    })
  }
}
