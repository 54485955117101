import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "price" ]

  connect() {
  }

  toggle(event) {

    Rails.ajax({
      type: "get",
      url: "/nettoprice?show_net_price=" + event.currentTarget.checked,
      success: function(data) {
        window.dispatchEvent(new CustomEvent("reload-prices"))
      }
    });

    this.priceTargets.forEach(item => {
      item.classList.toggle("hidden")
    });
  }
}
