import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "modal", "ajaxResponse" ]
  static values = { input: String }

  open(event) {
    if ($(this.modalTarget).hasClass("show")) return

    this.inputValue = event.currentTarget.value

    if (this._isInputBlank() || this.ajaxResponseTarget.innerHTML === "") {
      this._setLoadSpinner()
      this._setResponse(event.currentTarget.dataset.url)
    }

    $(this.modalTarget).modal({show: true, focus: false, backdrop: true})
  }

  close() {
    $(this.modalTarget).modal("hide")

    if (this._isInputBlank()) {
      this._removeResponse()
    }
  }

  _isInputBlank() {
    return !Boolean(this.inputValue?.trim())
  }

  _setLoadSpinner() {
    this.ajaxResponseTarget.innerHTML = "<div class='spinner-border text-primary' role='status'><span class='sr-only'>Indlæser...</span></div>"
  }

  _setResponse(url) {
    let target = this.ajaxResponseTarget

    Rails.ajax({
      type: "get",
      url: url,
      success: function(data) {
        target.innerHTML = data.body.innerHTML
      }
    });
  }

  _removeResponse() {
    this.ajaxResponseTarget.innerHTML = ""
  }
}
