import { Controller } from "stimulus"
import List from "list.js"

export default class extends Controller {
  static targets = [ "sortable" ]

  connect() {
    new List(this.element, this.options)
  }

  get options() {
    return {
      valueNames: this.sortableTargets.map(target => target.dataset.sort )
    }
  }
}
