import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "lastTenList" ]

  refresh() {
    let target = this.lastTenListTarget

    Rails.ajax({
      type: "get",
      url: this.data.get("url"),
      success: function(data) {
        target.innerHTML = data.body.innerHTML
      }
    });
  }
}
