import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "modal" ]

  openModal(event) {
    event.preventDefault();
    // alert("modal");
    $(this.modalTarget).modal("show")
  }
}
